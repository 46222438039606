const middleware = {}

middleware['checkout'] = require('../middleware/checkout.js')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['handleAcceptLanguage'] = require('../middleware/handleAcceptLanguage.js')
middleware['handleAcceptLanguage'] = middleware['handleAcceptLanguage'].default || middleware['handleAcceptLanguage']

middleware['handleCurrency'] = require('../middleware/handleCurrency.js')
middleware['handleCurrency'] = middleware['handleCurrency'].default || middleware['handleCurrency']

middleware['handleLocale'] = require('../middleware/handleLocale.js')
middleware['handleLocale'] = middleware['handleLocale'].default || middleware['handleLocale']

middleware['handleMarket'] = require('../middleware/handleMarket.js')
middleware['handleMarket'] = middleware['handleMarket'].default || middleware['handleMarket']

middleware['isAuthenticated'] = require('../middleware/isAuthenticated.js')
middleware['isAuthenticated'] = middleware['isAuthenticated'].default || middleware['isAuthenticated']

middleware['redirect'] = require('../middleware/redirect.ts')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
